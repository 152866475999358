import React, { FC, ReactElement } from 'react';
import RouteElement, { RouteElementProps } from '../RouteElement';
import { Route, Routes } from 'react-router-dom';

export interface RouteConfig {
	path?: string;
	index?: boolean;
	element: ReactElement;
	type: RouteElementProps['type'];
}

export interface RouteSectionProps {
	children: Record<string, RouteConfig>;
}

const RouteSection: FC<RouteSectionProps> = ({ children: routes }) => {
	return (
		<Routes>
			{Object.entries(routes).map(([key, { path, index, element, type }]) => {
				return (
					<Route
						key={`route-${key}`}
						path={path}
						index={index}
						element={<RouteElement type={type}>{element}</RouteElement>}
					/>
				);
			})}
		</Routes>
	);
};

export default RouteSection;
