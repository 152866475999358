import {
	DocumentNode,
	OperationVariables,
	QueryHookOptions,
	useQuery,
} from "@apollo/client";

export const getQueryHook =
	<TData, TVariables extends OperationVariables>(query: DocumentNode) =>
	(options?: QueryHookOptions<TData, TVariables>) =>
		useQuery<TData, TVariables>(query, options);
