import React, { useEffect, useRef, useState } from 'react';
import Map, { MapLayerMouseEvent, MapRef } from 'react-map-gl';
import { useMapState } from '../../../../hooks/useMapState';
import Dashboard from '../../../../components/layout/Dashboard';
import { Box, useColorScheme } from '@mui/joy';
import Legend from './components/Legend';
import 'mapbox-gl/dist/mapbox-gl.css';
import Fractal, { FractalProps } from './components/Fractal';
import Collage from './components/Collage';
import { useSearch } from '../../../../components/layout/SearchProvider';
import { schema } from '../../../../schema';
import { useSnackbar } from '../../../../components/display/feedback/SnackbarProvider';
import TriggerInput from '../../../../components/input/TriggerInput';
import { MdSearch } from 'react-icons/md';

const places = [
	'San Fransisco, CA',
	'Los Angeles, CA',
	'New York, NY',
	'Chicago, IL',
	'Seattle, WA',
	'Portland, OR',
	'Denver, CO',
	'Phoenix, AZ',
	'San Diego, CA',
	'Philadelphia, PA',
	'San Antonio, TX',
];

const randomPlace = places[Math.floor(Math.random() * places.length)];

export interface EnergyMapProps {
	forceRefresh?: boolean;
}

const colorOptions = [
	'Bright Blue',
	'Cyan',
	'Indigo',
	'Jade',
	'Burnt Siena',
	'Fuchsia',
];

const color = colorOptions[Math.floor(Math.random() * colorOptions.length)];

const EnergyMap: React.FC<EnergyMapProps> = ({ forceRefresh = false }) => {
	const { mode } = useColorScheme();
	const { enqueue } = useSnackbar();
	const token = process.env.REACT_APP_MAPBOX_TOKEN;

	const { search, setSearch } = useSearch();

	const mapRef = useRef<MapRef>(null);

	const [fractals, setFractals] = useState<FractalProps['children'][]>([]);

	const [clicked, setClicked] = useState<mapboxgl.MapboxGeoJSONFeature | null>(
		null
	);

	if (!token) {
		throw new Error('Missing Mapbox token');
	}

	const [viewState, setViewState] = useMapState('energy');

	const getFeatures = (count: number) => {
		if (mapRef.current) {
			const map = mapRef.current.getMap();

			// Get the camera's position
			const cameraPosition = map.getCenter();

			// Get the features that are currently visible in the map
			const results = map.queryRenderedFeatures(
				[
					[0, 0],
					[map.getCanvas().width, map.getCanvas().height],
				],
				{
					layers: ['PowerPlants'],
				}
			);

			// Get the projection of each feature and calculate its distance from the camera
			const featuresWithDistance = results.map((f) => {
				const geometry = f.geometry as GeoJSON.Polygon;
				const polygonCenter = geometry.coordinates[0].reduce(
					(acc, cur) => {
						return [acc[0] + cur[0], acc[1] + cur[1]];
					},
					[0, 0]
				);

				const featureCenter: mapboxgl.LngLatLike = [
					polygonCenter[0] / geometry.coordinates[0].length,
					polygonCenter[1] / geometry.coordinates[0].length,
				];

				const point = map.project(featureCenter);

				const yBump = 0;
				const xBump = 0;

				// Calculate the distance between the feature's center and the camera's position
				const distance = Math.sqrt(
					Math.pow(cameraPosition.lng - featureCenter[0], 2) +
						Math.pow(cameraPosition.lat - featureCenter[1], 2)
				);

				return {
					x: point.x + xBump,
					y: point.y + yBump,
					properties: f.properties,
					distance: distance,
				};
			});

			// Sort the features by distance and select the top 5
			const closestFeatures = featuresWithDistance
				.sort((a, b) => a.distance - b.distance)
				.slice(0, count);

			setFractals(closestFeatures as FractalProps['children'][]);
		}
	};

	// const refreshString = forceRefresh ? `?fresh=${new Date().getTime()}` : '';
	const refreshString = '';

	const lightStyle =
		'mapbox://styles/morganddoane/clo3j9r8u007701r8h5e4htsl' + refreshString;
	const darkStyle =
		'mapbox://styles/morganddoane/clo3eslbl007601r8cq4q0dd1' + refreshString;

	const mapStyle =
		'mapbox://styles/morganddoane/clo7sj24w009s01ri2zry7arz' + refreshString;

	const onClick = (event: MapLayerMouseEvent) => {
		if (event.features && event.features.length > 0) {
			const clickedFeature = event.features[0];
			setClicked(clickedFeature);
		}
	};

	// const [color, setColor] = useState(
	// 	colorOptions[Math.floor(Math.random() * colorOptions.length)]
	// );

	const onRemix = () => {
		// if (mapRef.current) {
		// 	const map = mapRef.current.getMap();
		// 	map.setPaintProperty('PowerPlants', 'fill-extrusion-color', color);
		// 	map.setPaintProperty('PowerLines', 'line-color', color);
		// }
	};

	const [geocode, { loading }] = schema.geocode.lazy({
		onCompleted: (data) => {
			mapRef.current?.flyTo({
				center: [data.Geocode.lon, data.Geocode.lat],
				zoom: 16,
				duration: 5000,
				pitch: 60,
				bearing: -45,
			});
		},
		onError: (error) => {
			enqueue({
				message: error.message,
				variant: 'error',
			});
		},
	});

	const [searched, setSearched] = useState('');

	useEffect(() => {
		if (search && search !== searched) {
			setSearched(search);
			geocode({
				variables: {
					place: search,
				},
			});
		}
	}, [search]);

	return (
		<Dashboard loading={loading}>
			<Box
				sx={{
					position: 'absolute',
					bottom: '24px',
					left: '24px',
					zIndex: 999,
				}}
			>
				<TriggerInput
					size="lg"
					threshold={2000}
					placeholder={randomPlace}
					startDecorator={<MdSearch />}
					value={search}
					onChange={setSearch}
				/>
			</Box>
			<Map
				styleDiffing={true}
				ref={mapRef}
				interactiveLayerIds={['PowerPlants', 'PowerLines']}
				onClick={onClick}
				mapboxAccessToken={token}
				{...viewState}
				onMove={(e) => {
					// setFractals([]);
					let newViewState = { ...e.viewState };
					onRemix();
					setViewState(newViewState);
				}}
				style={{ width: '100%', height: '100%' }}
				mapStyle={lightStyle}
				projection={{
					name: 'globe',
				}}
				maxZoom={22}
				minZoom={0}
				maxPitch={85}
			/>
			<Collage onClose={() => setClicked(null)} color={color}>
				{clicked}
			</Collage>
			{/* <DetailDrawer onClose={() => setClicked(null)}>{clicked}</DetailDrawer> */}
			<Legend
				onRemix={onRemix}
				onLocate={(lon, lat, duration) => {
					mapRef.current?.flyTo({
						center: [lon, lat],
						zoom: 16,
						duration: duration || 2000,
						pitch: 60,
						bearing: -45,
					});
				}}
			/>
			{/* <>
				{fractals.map((f, i) => (
					<Fractal key={`fractal_${i}`}>{f}</Fractal>
				))}
			</> */}
		</Dashboard>
	);
};

export default EnergyMap;
