import { gql } from '@apollo/client';

export class ProfileDetail {
	uid!: string;
	firstName!: string;
	lastName!: string;
	email!: string;
	dateCreated!: number;
	name!: string;
	photoURL!: string;
}

export const ProfileDetailFragment = gql`
	fragment ProfileDetailFragment on ProfileDetail {
		uid
		firstName
		lastName
		name
		email
		dateCreated
		photoURL
	}
`;
