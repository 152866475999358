import { gql } from '@apollo/client';
import { ProfileDetailFragment } from './ProfileDetail';

export const ProfileDetailQuery = gql`
	${ProfileDetailFragment}
	query ProfileDetailQuery($uid: String!) {
		ProfileDetail(uid: $uid) {
			...ProfileDetailFragment
		}
	}
`;

export const ProfileDetailListQuery = gql`
	${ProfileDetailFragment}
	query ProfileDetailListQuery($filter: ProfileDetailFilter!) {
		ProfileDetailList(filter: $filter) {
			...ProfileDetailFragment
		}
	}
`;

export const ProfileDetailCreateMutation = gql`
	${ProfileDetailFragment}
	mutation ProfileDetailCreateMutation($data: ProfileDetailCreate!) {
		ProfileDetailCreate(data: $data) {
			...ProfileDetailFragment
		}
	}
`;

export const ProfileDetailUpdateMutation = gql`
	${ProfileDetailFragment}
	mutation ProfileDetailUpdateMutation($data: ProfileDetailUpdate!) {
		ProfileDetailUpdate(data: $data) {
			...ProfileDetailFragment
		}
	}
`;
