import { gql } from '@apollo/client';
import { getQueryHook } from '../getQueryHook';
import { getLazyQueryHook } from '../getLazyQueryHook';

export class Geocode {
	place!: string;
	lon!: number;
	lat!: number;
}

export const GeocodeFragment = gql`
	fragment GeocodeFragment on Geocode {
		place
		lon
		lat
	}
`;

export const GeocodeQuery = gql`
	${GeocodeFragment}
	query Geocode($place: String!) {
		Geocode(place: $place) {
			...GeocodeFragment
		}
	}
`;

export const geocode = {
	get: getQueryHook<{ Geocode: Geocode }, { place: string }>(GeocodeQuery),
	lazy: getLazyQueryHook<{ Geocode: Geocode }, { place: string }>(GeocodeQuery),
};
