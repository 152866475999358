import { Box } from '@mui/joy';
import React, { FC, useEffect, useState } from 'react';
import {
	SizeKey,
	useScreenSize,
} from '../../../../../../components/layout/useScreenSize/useScreenSize';
import { motion } from 'framer-motion';
import CollageLayer, { CollageLayerProps } from './components/CollageLayer';
import mapboxgl from 'mapbox-gl';

export interface CollageProps {
	children: mapboxgl.MapboxGeoJSONFeature | null;
	onClose: () => void;
	color: string;
}

const Collage: FC<CollageProps> = ({ children: feature, onClose, color }) => {
	const { size } = useScreenSize();

	const [layers, setLayers] = useState<Omit<CollageLayerProps, 'index'>[]>([]);

	const getDimensions = (size: SizeKey): [length: number, height: number] => {
		const basicRation = [16, 9];

		const cellsWidth: Record<SizeKey, number> = {
			phone: 7,
			tablet: 13,
			desktop: 19,
			wide: 20,
		};

		const length = cellsWidth[size];
		const height = Math.round((length / basicRation[0]) * basicRation[1]);

		return [length, height];
	};

	const dimensions = getDimensions(size);

	const containerVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	const gridItemVariants = {
		hidden: { opacity: 0, y: -10 },
		visible: (rowCol: [number, number]) => ({
			opacity: 1,
			y: 0,
			transition: {
				delay: (rowCol[0] + rowCol[1] * dimensions[0]) * 0.003,
			},
		}),
	};

	const getCombinedCell = (
		coverage: number,
		avoid?: { row: number; column: number }
	): {
		x: number;
		y: number;
		width: number;
		height: number;
		column: number;
		row: number;
	} => {
		const heightMultiplier = 1 + Math.random() * 0.5;
		const xCount = Math.floor(dimensions[0] * coverage) | 1;
		const yCount = Math.floor(dimensions[1] * coverage * heightMultiplier) | 1;

		const maxX = dimensions[0] - xCount;
		const maxY = dimensions[1] - yCount;

		const xOptions = Array.from({ length: maxX + 1 }).map((_, index) => index);
		const yOptions = Array.from({ length: maxY + 1 }).map((_, index) => index);

		const xIndex = xOptions[Math.floor(Math.random() * xOptions.length)];
		const yIndex = yOptions[Math.floor(Math.random() * yOptions.length)];

		const cellWidth = 1 / dimensions[0];
		const cellHeight = 1 / dimensions[1];

		const screenX = xIndex * cellWidth;
		const screenY = yIndex * cellHeight;

		const combinedWidth = cellWidth * xCount;
		const combinedHeight = cellHeight * yCount;

		// if the cell is touching an edge, move it one cell away from the edge

		const isTouchingEdge =
			yIndex === 0 || xIndex === 0 || yIndex === maxY || xIndex === maxX;

		if (isTouchingEdge) {
			return getCombinedCell(coverage);
		}

		if (avoid && avoid.row === yIndex && avoid.column === xIndex) {
			return getCombinedCell(coverage);
		}

		return {
			x: screenX,
			y: screenY,
			width: combinedWidth,
			height: combinedHeight,
			column: xIndex,
			row: yIndex,
		};
	};

	const enqueue = (newFeature: mapboxgl.MapboxGeoJSONFeature) => {
		const cell = getCombinedCell(0.6);
		const textCell = getCombinedCell(0.2);
		setLayers((layers) => [
			...layers,
			{
				...cell,
				children: newFeature,
				color,
			},
			{
				...textCell,
				children: newFeature,
				color,
			},
		]);
	};

	useEffect(() => {
		if (layers.length === 0 && feature) {
			enqueue(feature);
		}
	}, [layers, feature]);

	const handleClose = () => {
		onClose();
		setLayers([]);
	};

	return feature ? (
		<motion.div
			onClick={handleClose}
			variants={containerVariants}
			initial="hidden"
			animate="visible"
			exit="hidden"
			style={{
				position: 'absolute',
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				zIndex: 100,
				display: 'grid',
				gridTemplateColumns: `repeat(${dimensions[0]}, 1fr)`,
				gridTemplateRows: `repeat(${dimensions[1]}, 1fr)`,
			}}
		>
			{Array.from({ length: dimensions[0] * dimensions[1] }).map((_, index) => {
				const rowIndex = Math.floor(index / dimensions[0]);
				const colIndex = index % dimensions[0];
				return (
					<motion.div
						key={index}
						variants={gridItemVariants}
						initial="hidden"
						animate="visible"
						custom={[rowIndex, colIndex]}
						style={{
							backgroundColor: 'rgba(0,0,0,0.5)',
							border: '1px solid rgba(255,255,255,0.1)',
						}}
					/>
				);
			})}
			{layers.map((layer, index) => (
				<CollageLayer key={`layer_${index}`} {...layer} index={index} />
			))}
		</motion.div>
	) : null;
};

export default Collage;
