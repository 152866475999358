import { chat } from './Chat/dataset';
import { clientContext } from './ClientContext/ClientContext';
import { geocode } from './Geocode/Geocode';
import { image } from './Image/Image';
import { profileDetail } from './ProfileDetail/dataset';

export const schema = {
	chat,
	clientContext,
	geocode,
	image,
	profileDetail,
};
