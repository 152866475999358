import React from 'react';
import Page from '../../components/layout/Page';
import CenterBox from '../../components/layout/CenterBox';
import {
	Box,
	Button,
	Card,
	FormControl,
	FormLabel,
	IconButton,
	Input,
	Typography,
} from '@mui/joy';
import DivText from '../../components/layout/DivText';
import { BsGoogle } from 'react-icons/bs';
import { useScreenSize } from '../../components/layout/useScreenSize/useScreenSize';
import { useAuthContext } from '../../auth/AuthContext';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useError } from '../../hooks/useError';
import { useLocation, useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
	const { state: navState } = useLocation();
	const nav = useNavigate();
	const { phone } = useScreenSize();

	const {
		signin: { google, email },
	} = useAuthContext();

	const [state, setState] = React.useState({
		email: navState?.email || '',
		password: navState?.password || '',
	});

	const [showPassword, setShowPassword] = React.useState(false);

	const error = google.error || email.error;
	useError(error);

	return (
		<Page>
			<CenterBox>
				<Card
					variant="plain"
					sx={{
						flex: 1,
						display: 'flex',
						flexFlow: 'column',
						borderRadius: phone ? 0 : undefined,
						p: 3,
					}}
				>
					<Box
						sx={{
							textAlign: 'center',
						}}
					>
						<Typography level="h1">Login</Typography>
					</Box>
					<Box />
					<DivText>with</DivText>
					<Box />
					<Box>
						<Button
							fullWidth
							color="neutral"
							variant="outlined"
							startDecorator={<BsGoogle />}
							size="lg"
							onClick={google.handler}
							loading={google.loading}
						>
							Google
						</Button>
					</Box>
					<Box />
					<DivText>or</DivText>
					<Box />
					<FormControl>
						<FormLabel>Email</FormLabel>
						<Input
							value={state.email}
							onChange={(e) => {
								setState({ ...state, email: e.target.value });
							}}
							placeholder="Email"
							variant="soft"
							size="lg"
						/>
					</FormControl>
					<Box />
					<FormControl>
						<FormLabel>Password</FormLabel>
						<Input
							value={state.password}
							onChange={(e) => {
								setState({ ...state, password: e.target.value });
							}}
							type={showPassword ? 'text' : 'password'}
							placeholder="Password"
							variant="soft"
							size="lg"
							endDecorator={
								<IconButton
									onClick={() => {
										setShowPassword(!showPassword);
									}}
								>
									{showPassword ? <MdVisibilityOff /> : <MdVisibility />}
								</IconButton>
							}
						/>
					</FormControl>
					<Box sx={{ flex: 1 }} />
					<Button
						onClick={() => {
							email.handler(state.email, state.password);
						}}
						size="lg"
						loading={email.loading}
					>
						Login
					</Button>
					<Box />
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<Typography
							level="title-sm"
							endDecorator={
								<Box>
									<Button
										onClick={() =>
											nav('/signup', {
												state: {
													email: state.email,
													password: state.password,
												},
											})
										}
										color="neutral"
										variant="plain"
									>
										Signup
									</Button>
								</Box>
							}
						>
							Need an account?
						</Typography>
					</Box>
				</Card>
				{!phone && <Box p={2} />}
			</CenterBox>
		</Page>
	);
};

export default Login;
