import { Button, IconButton } from '@mui/joy';
import React, { FC, ReactNode } from 'react';
import { useScreenSize } from '../../../../../useScreenSize/useScreenSize';

export interface NavButtonProps {
	icon: ReactNode;
	onClick: () => void;
	label?: string;
}

const NavButton: FC<NavButtonProps> = ({ icon, label, onClick }) => {
	const { phone } = useScreenSize();

	if (phone && label)
		return (
			<Button
				color="neutral"
				variant="soft"
				startDecorator={icon}
				onClick={onClick}
			>
				{label}
			</Button>
		);
	else
		return (
			<IconButton color="neutral" variant="soft" onClick={onClick}>
				{icon}
			</IconButton>
		);
};

export default NavButton;
