import { Input, InputProps } from '@mui/joy';
import React, { FC } from 'react';

export interface TriggerInputProps
	extends Omit<InputProps, 'value' | 'onChange'> {
	threshold?: number;
	value: string;
	onChange: (value: string) => void;
}

const TriggerInput: FC<TriggerInputProps> = ({
	value,
	onChange,
	threshold = 500,
	...rest
}) => {
	const [inputValue, setInputValue] = React.useState(value);

	// debounce the input value, so that the onChange function is only called
	// after the user has stopped typing for the threshold amount of time
	// reset the timeout every time the user types
	React.useEffect(() => {
		const timeout = setTimeout(() => {
			console.log('Triggered');
			onChange(inputValue);
		}, threshold);

		return () => {
			clearTimeout(timeout);
		};
	}, [inputValue, onChange, threshold]);

	return (
		<Input
			{...rest}
			value={inputValue}
			onChange={(e) => setInputValue(e.target.value)}
		/>
	);
};

export default TriggerInput;
