import { Button, ButtonProps } from '@mui/joy';
import React, { FC, useEffect } from 'react';
import { MdCopyAll, MdCheck } from 'react-icons/md';
import { useSnackbar } from '../../display/feedback/SnackbarProvider';

export interface CopyButtonProps
	extends Omit<ButtonProps, 'children' | 'endDecorator' | 'onClick'> {
	value: string;
	children?: string;
}

const CopyButton: FC<CopyButtonProps> = ({ value, children, ...rest }) => {
	const { enqueue } = useSnackbar();

	const [copied, setCopied] = React.useState(false);

	const Icon = copied ? MdCheck : MdCopyAll;

	useEffect(() => {
		if (copied) {
			const timeout = setTimeout(() => {
				setCopied(false);
			}, 1000);
			return () => clearTimeout(timeout);
		}
	}, [copied]);

	const onClick = () => {
		navigator.clipboard.writeText(value);
		setCopied(true);
		enqueue({
			message: 'Copied to clipboard',
			variant: 'success',
		});
	};

	return (
		<Button {...rest} onClick={onClick} endDecorator={<Icon />}>
			{children || 'Copy'}
		</Button>
	);
};

export default CopyButton;
