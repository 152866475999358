import { Box, Divider, Typography, TypographyProps } from '@mui/joy';
import React, { FC } from 'react';

export interface DivTextProps extends TypographyProps {}

const DivText: FC<DivTextProps> = (props) => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
			<Box sx={{ flex: 1, paddingTop: '2px' }}>
				<Divider />
			</Box>
			<Box>
				<Typography {...props} />
			</Box>
			<Box sx={{ flex: 1, paddingTop: '2px' }}>
				<Divider />
			</Box>
		</Box>
	);
};

export default DivText;
