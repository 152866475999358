import { gql } from '@apollo/client';
import { ChatMessage } from './ChatMessage';

export class Chat {
	tokens!: number;
	messages!: ChatMessage[];
}

export const ChatFragment = gql`
	fragment ChatFragment on Chat {
		tokens
		messages {
			...ChatMessageFragment
		}
	}
`;
