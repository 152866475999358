import { gql } from '@apollo/client';
import { ChatFragment } from './Chat';
import { ChatMessageFragment } from './ChatMessage';

export const ChatQuery = gql`
	${ChatFragment}
	${ChatMessageFragment}
	query Chat($messages: [ChatMessageInput!]!) {
		Chat(messages: $messages) {
			...ChatFragment
		}
	}
`;
