import { Avatar, Box, useColorScheme, useTheme } from '@mui/joy';
import React, { ReactNode } from 'react';
import NavButton from './components/NavButton';
import { MdDarkMode, MdLightMode, MdMap, MdSearch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../../auth/AuthContext';
import { useScreenSize } from '../../../useScreenSize/useScreenSize';
import Instruction, { InstructionProps } from './components/Instruction';
import TriggerInput from '../../../../input/TriggerInput';
import { useSearch } from '../../../SearchProvider';

const Sidebar: React.FC = () => {
	const { mode, setMode } = useColorScheme();
	const nav = useNavigate();
	const { palette } = useTheme();
	const { user } = useAuthContext();
	const { phone } = useScreenSize();
	const { search, setSearch } = useSearch();

	const paths: {
		icon: ReactNode;
		path: string;
	}[] = [
		// {
		// 	icon: <MdMap />,
		// 	path: '/app',
		// },
	];

	const instructions: Omit<InstructionProps, 'index'>[] = [
		{
			label: 'Welcome to the Energy API',
			instructions: [
				'The Energy API is a tool for exploring the energy infrastructure of the United States.',
				"It's a place to learn about the energy systems that power our lives, and to imagine how they might be different.",
			],
		},
		{
			label: 'Overview',
			instructions: [
				'The map is organized into layers, each of which represents a different type of energy infrastructure.',
				'The vertical extrusions represent the location and capacity of American power plants.',
				'The veins and their corresponding lineweight represent the path and capacity of transmission lines.',
			],
		},
		{
			label: 'Search',
			instructions: [
				'Begin by entering your address or the name of a city that you would like to explore in the bottom search bar.',
			],
		},
		{
			label: 'Interact',
			instructions: [
				"Clicking on a power plant unfolds it's details, constructing a narrative about the energy source and it's context.",
			],
		},
	];

	return (
		<Box
			sx={{
				border: `1px solid ${palette.divider}`,
				p: 1,
				display: 'flex',
				flexFlow: phone ? 'row' : 'column',
				alignItems: 'center',
				background: palette.background.level1,
				position: 'relative',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexFlow: 'column',
					gap: 2.5,
					p: 2,
				}}
			>
				{instructions.map((inst, index) => (
					<Instruction key={`inst_${index}`} index={index} {...inst} />
				))}
			</Box>

			<Box sx={{ flex: 1 }} />
			<Box
				sx={{
					display: 'flex',
					flexFlow: phone ? 'row' : 'column',
					gap: 1,
				}}
			>
				{paths.map(({ icon, path }, index) => (
					<NavButton
						label="Energy Map"
						key={`path-${index}`}
						icon={icon}
						onClick={() => {
							nav(path);
						}}
					/>
				))}
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexFlow: 'row',
					gap: 1,
				}}
			>
				<NavButton
					icon={mode === 'dark' ? <MdLightMode /> : <MdDarkMode />}
					onClick={() => {
						setMode(mode === 'dark' ? 'light' : 'dark');
					}}
				/>
				<Avatar
					src={user?.photoURL ?? undefined}
					alt={user?.displayName || ''}
					sx={{
						cursor: 'pointer',
					}}
					onClick={() => {
						nav('/app/account');
					}}
				/>
			</Box>
		</Box>
	);
};

export default Sidebar;
