import React from 'react';
import Page from '../../components/layout/Page';
import CenterBox from '../../components/layout/CenterBox';
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Input,
	Tooltip,
	Typography,
} from '@mui/joy';
import { useAuthContext } from '../../auth/AuthContext';
import { ProfileDetailCreate } from '../../schema/ProfileDetail/ProfileDetailCreate';
import { useScreenSize } from '../../components/layout/useScreenSize/useScreenSize';
import { schema } from '../../schema';
import { useSnackbar } from '../../components/display/feedback/SnackbarProvider';
import { ClientContextQuery } from '../../schema/ClientContext/ClientContext';

const ProfileSetup: React.FC = () => {
	const { user } = useAuthContext();
	const { phone } = useScreenSize();
	const { enqueue } = useSnackbar();

	const nameParts = user?.displayName?.split(' ') ?? [];

	const [state, setState] = React.useState<ProfileDetailCreate>({
		firstName: nameParts[0] ?? '',
		lastName: nameParts[1] ?? '',
		email: user?.email ?? '',
	});

	const [create, { loading }] = schema.profileDetail.create({
		variables: {
			data: state,
		},
		onCompleted: () => {
			enqueue({
				variant: 'success',
				message: 'Profile created!',
			});
		},
		onError: (e) => {
			enqueue({
				variant: 'error',
				message: e.message,
			});
		},
		refetchQueries: [ClientContextQuery],
	});

	const getHoldup = () => {
		if (!state.firstName) return 'Please enter your first name';
		else if (!state.lastName) return 'Please enter your last name';
		else if (!state.email) return 'Please enter your email';
		else return null;
	};

	const holdup = getHoldup();

	return (
		<Page>
			<CenterBox>
				<Box sx={{ display: 'flex', flexFlow: 'column', gap: 2, p: 2 }}>
					<Box
						sx={{
							fontSize: '2rem',
						}}
					>
						👋
					</Box>
					<Typography level="h1">Welcome to The Energy API</Typography>
					<Typography>
						We need a little more information to get you started.
					</Typography>
					<Box p={1} />
					<Box
						sx={{
							display: 'flex',
							flexFlow: 'column',
							gap: 2,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								gap: 2,
								flexFlow: phone ? 'column' : 'row',
							}}
						>
							<FormControl>
								<FormLabel>First name</FormLabel>
								<Input
									autoFocus
									placeholder="First name"
									value={state.firstName}
									onChange={(e) => {
										setState({
											...state,
											firstName: e.target.value,
										});
									}}
								/>
							</FormControl>
							<FormControl>
								<FormLabel>Last name</FormLabel>
								<Input
									placeholder="Last name"
									value={state.lastName}
									onChange={(e) => {
										setState({
											...state,
											lastName: e.target.value,
										});
									}}
								/>
							</FormControl>
						</Box>
						<FormControl>
							<FormLabel>Email</FormLabel>
							<Input
								type="email"
								placeholder="Email"
								value={state.email}
								onChange={(e) => {
									setState({
										...state,
										email: e.target.value,
									});
								}}
							/>
						</FormControl>
						<Box />
						<Tooltip arrow placement="bottom" title={holdup || ''}>
							<Box>
								<Button
									onClick={() => create()}
									loading={loading}
									size="lg"
									disabled={Boolean(holdup)}
									fullWidth
								>
									Let's go!
								</Button>
							</Box>
						</Tooltip>
						{!phone && <Box p={4} />}
					</Box>
				</Box>
			</CenterBox>
		</Page>
	);
};

export default ProfileSetup;
