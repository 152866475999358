import React from 'react';
import RouteSection from '../../auth/RouteSection';
import Account from './components/Account';
import { ProfileDetail } from '../../schema/ProfileDetail/ProfileDetail';
import { schema } from '../../schema';
import EnergyMap from './components/EnergyMap';

const App: React.FC = () => {
	const [detail, setDetail] = React.useState<ProfileDetail | null>(null);

	schema.clientContext.get({
		onCompleted: (data) => {
			if (data && data.ClientContext.profileDetail) {
				setDetail(data.ClientContext.profileDetail);
			}
		},
	});

	return (
		<RouteSection>
			{{
				energymap: {
					index: true,
					element: <EnergyMap />,
					type: 'private',
				},
				account: {
					path: 'account/*',
					element: <Account />,
					type: 'private',
				},
			}}
		</RouteSection>
	);
};

export default App;
