import { gql } from '@apollo/client';
import { ChatRole } from './ChatRole';

export class ChatMessage {
	content!: string;
	role!: ChatRole;
}

export const ChatMessageFragment = gql`
	fragment ChatMessageFragment on ChatMessage {
		content
		role
	}
`;
