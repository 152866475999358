import { getMutationHook } from '../getMutationHook';
import { getQueryHook } from '../getQueryHook';
import { ProfileDetail } from './ProfileDetail';
import { ProfileDetailCreate } from './ProfileDetailCreate';
import { ProfileDetailFilter } from './ProfileDetailFilter';
import { ProfileDetailUpdate } from './ProfileDetailUpdate';
import {
	ProfileDetailQuery,
	ProfileDetailListQuery,
	ProfileDetailCreateMutation,
} from './operations';

export const profileDetail = {
	get: getQueryHook<{ ProfileDetail: ProfileDetail }, { id: string }>(
		ProfileDetailQuery
	),
	list: getQueryHook<
		{ ProfileDetailList: ProfileDetail[] },
		{ filter: ProfileDetailFilter }
	>(ProfileDetailListQuery),
	create: getMutationHook<
		{ ProfileDetailCreate: ProfileDetail },
		{ data: ProfileDetailCreate }
	>(ProfileDetailCreateMutation),
	update: getMutationHook<
		{ ProfileDetailUpdate: ProfileDetail },
		{ id: string; data: ProfileDetailUpdate }
	>(ProfileDetailCreateMutation),
};
