import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const firebaseConfig = {
	apiKey: 'AIzaSyDLrnQLhc1-q65SPDfP-uassGXJ7e9oYYg',
	authDomain: 'energy-402918.firebaseapp.com',
	projectId: 'energy-402918',
	storageBucket: 'energy-402918.appspot.com',
	messagingSenderId: '346356834264',
	appId: '1:346356834264:web:2f6de58935c7c6ff23dcad',
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
