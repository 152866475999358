import {
	DocumentNode,
	LazyQueryHookOptions,
	OperationVariables,
	useLazyQuery,
} from '@apollo/client';

export const getLazyQueryHook =
	<TData, TVariables extends OperationVariables>(query: DocumentNode) =>
	(options?: LazyQueryHookOptions<TData, TVariables>) =>
		useLazyQuery<TData, TVariables>(query, options);
