import { CssVarsProvider, extendTheme } from '@mui/joy';
import React, { PropsWithChildren } from 'react';

const theme = extendTheme({
	fontFamily: {
		display: 'NovaletraSerif',
	},
});

const Theme: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<CssVarsProvider defaultColorScheme="dark" theme={theme}>
			{children}
		</CssVarsProvider>
	);
};

export default Theme;
