import { useEffect } from 'react';
import { useSnackbar } from '../components/display/feedback/SnackbarProvider';

export const useError = (watch: unknown, message?: string) => {
	const { enqueue } = useSnackbar();

	useEffect(() => {
		if (watch) {
			enqueue({
				message:
					message ||
					(watch instanceof Error ? watch.message : 'Something went wrong'),
				variant: 'error',
			});
		}
	}, [watch]);
};
