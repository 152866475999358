import React from 'react';
import { Box, IconButton, Tooltip, useTheme } from '@mui/joy';
import { IoMdLocate } from 'react-icons/io';

export const plantColors = {
	hydrocarbon: '#F7F540',
	renewable: '#13AE51',
	other: '#3C3C3C',
};

export interface LegendProps {
	onLocate: (lon: number, lat: number, duration?: number) => void;
	onRemix: () => void;
}

const Legend: React.FC<LegendProps> = ({ onLocate, onRemix }) => {
	const { palette } = useTheme();

	return (
		<Box
			sx={{
				position: 'absolute',
				bottom: '24px',
				right: '24px',
				display: 'flex',
				alignItems: 'flex-end',
				gap: 1,
			}}
		>
			<Tooltip title="Go to your current location" arrow>
				<IconButton
					onClick={() => {
						navigator.geolocation.getCurrentPosition(
							(position) => {
								onLocate(
									position.coords.longitude,
									position.coords.latitude,
									5000
								);
							},
							(error) => {
								console.log(error);
							}
						);
					}}
					sx={{
						background: palette.background.body,
					}}
					size="lg"
					variant="outlined"
				>
					<IoMdLocate />
				</IconButton>
			</Tooltip>
		</Box>
	);
};

export default Legend;
