import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import RouteSection from './auth/RouteSection';
import Landing from './scenes/Landing';
import Login from './scenes/Login';
import Signup from './scenes/Signup';
import Blocker from './scenes/Blocker';
import AuthContext from './auth/AuthContext';
import App from './scenes/App';
import Theme from './components/layout/Theme';
import ApolloWrapper from './auth/ApolloWrapper';
import SnackbarProvider from './components/display/feedback/SnackbarProvider';
import Logout from './scenes/Logout';
import SearchProvider from './components/layout/SearchProvider';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<Theme>
			<SnackbarProvider>
				<BrowserRouter>
					<AuthContext>
						<ApolloWrapper>
							<SearchProvider>
								<RouteSection>
									{{
										landing: {
											index: true,
											type: 'public',
											element: <Landing />,
										},
										login: {
											path: '/login',
											type: 'anonymous',
											element: <Login />,
										},
										signup: {
											path: '/signup',
											type: 'anonymous',
											element: <Signup />,
										},
										logout: {
											path: '/logout',
											type: 'public',
											element: <Logout />,
										},
										app: {
											path: '/app/*',
											type: 'private',
											element: <App />,
										},
										catch: {
											path: '*',
											type: 'public',
											element: <Blocker />,
										},
									}}
								</RouteSection>
							</SearchProvider>
						</ApolloWrapper>
					</AuthContext>
				</BrowserRouter>
			</SnackbarProvider>
		</Theme>
	</React.StrictMode>
);
