import { Box, Typography, useTheme } from '@mui/joy';
import React, { FC, ReactNode } from 'react';

export interface InstructionProps {
	index: number;
	label: string;
	instructions: string[];
	action?: ReactNode;
}

const Instruction: FC<InstructionProps> = ({
	index,
	label,
	instructions,
	action,
}) => {
	const { palette } = useTheme();

	return (
		<Box>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
				<Box
					sx={{
						height: '24px',
						width: '24px',
						borderRadius: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						background: palette.background.level2,
					}}
				>
					<Typography>{index + 1}</Typography>
				</Box>
				<Box
					sx={{
						paddingTop: 0.5,
					}}
				>
					<Typography level="h2">{label}</Typography>
				</Box>
			</Box>
			<Box p={0.75} />
			<Box sx={{ display: 'flex', flexFlow: 'column', gap: 1, paddingLeft: 4 }}>
				{instructions.map((inst, index) => (
					<Typography
						level="h4"
						sx={{
							maxWidth: '320px',
						}}
						key={`inst:${index}:${index}`}
					>
						{inst}
					</Typography>
				))}
			</Box>
			{action && (
				<Box
					sx={{
						paddingLeft: 4,
					}}
				>
					<Box p={0.75} />
					{action}
				</Box>
			)}
		</Box>
	);
};

export default Instruction;
