import { useState, useEffect } from 'react';
import { ViewState } from 'react-map-gl';

// Salt Lake City
const defaultState: ViewState = {
	longitude: -111.891,
	latitude: 40.7608,
	zoom: 10,
	bearing: 45,
	pitch: 45,
	padding: {
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
};

export const useMapState = (
	id: string
): [ViewState, React.Dispatch<React.SetStateAction<ViewState>>] => {
	const fromStorage = localStorage.getItem(`mapState-${id}`);

	const tuple = useState<ViewState>(
		fromStorage ? JSON.parse(fromStorage) : defaultState
	);

	// debounce setting the state to storage
	// so we don't write to storage on every map move
	const [state] = tuple;

	useEffect(() => {
		const timeout = setTimeout(() => {
			localStorage.setItem(`mapState-${id}`, JSON.stringify(state));
		}, 500);
		return () => clearTimeout(timeout);
	}, [state, id]);

	return tuple;
};
