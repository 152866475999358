import React, { FC, PropsWithChildren } from 'react';
import Page from '../Page';
import Sidebar from './components/Sidebar';
import { Box, LinearProgress } from '@mui/joy';
import { useScreenSize } from '../useScreenSize/useScreenSize';

export interface DashboardProps {
	loading?: boolean;
	error?: Error | null;
}

const Dashboard: FC<PropsWithChildren<DashboardProps>> = ({
	children,
	loading = false,
	error = null,
}) => {
	const { phone } = useScreenSize();
	return (
		<Page>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					alignItems: phone ? undefined : 'stretch',
					flexFlow: phone ? 'column' : 'row',
				}}
			>
				<Sidebar />
				<Box sx={{ flex: 1, position: 'relative' }}>
					{loading && (
						<Box
							sx={{
								position: 'absolute',
								left: 0,
								top: 0,
								right: 0,
								zIndex: 999,
							}}
						>
							<LinearProgress color="neutral" />
						</Box>
					)}
					{children}
				</Box>
			</Box>
		</Page>
	);
};

export default Dashboard;
