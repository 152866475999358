import { gql } from '@apollo/client';
import {
	ProfileDetail,
	ProfileDetailFragment,
} from '../ProfileDetail/ProfileDetail';
import { getQueryHook } from '../getQueryHook';

export class ClientContext {
	profileDetail!: ProfileDetail | null;
}

export const ClientContextQuery = gql`
	${ProfileDetailFragment}
	query ClientContextQuery {
		ClientContext {
			profileDetail {
				...ProfileDetailFragment
			}
		}
	}
`;

export const clientContext = {
	get: getQueryHook<
		{
			ClientContext: ClientContext;
		},
		{}
	>(ClientContextQuery),
};
