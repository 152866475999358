import React, { PropsWithChildren, useEffect } from 'react';
import {
	GoogleAuthProvider,
	User,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	signInWithPopup,
	createUserWithEmailAndPassword,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { firebaseAuth } from './firebase';

export interface AuthContextInterface {
	user: User | null;
	loading: boolean;
	error: Error | null;
	signup: {
		email: {
			handler: (email: string, password: string) => Promise<void>;
			loading: boolean;
			error: Error | null;
		};
	};
	signout: {
		handler: () => Promise<void>;
		loading: boolean;
		error: Error | null;
	};
	signin: {
		email: {
			handler: (email: string, password: string) => Promise<void>;
			loading: boolean;
			error: Error | null;
		};
		google: {
			handler: () => Promise<void>;
			loading: boolean;
			error: Error | null;
		};
	};
}

const AuthContext = React.createContext<AuthContextInterface>({
	user: null,
	loading: false,
	error: null,
	signout: {
		handler: async () => {},
		loading: false,
		error: null,
	},
	signin: {
		email: {
			handler: async () => {},
			loading: false,
			error: null,
		},
		google: {
			handler: async () => {},
			loading: false,
			error: null,
		},
	},
	signup: {
		email: {
			handler: async () => {},
			loading: false,
			error: null,
		},
	},
});

const AuthWrapper: React.FC<PropsWithChildren> = ({ children }) => {
	const nav = useNavigate();

	// Auth state
	const [user, setUser] = React.useState<User | null>(null);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState<Error | null>(null);

	// Email
	const [emailLoading, setEmailLoading] = React.useState(false);
	const [emailError, setEmailError] = React.useState<Error | null>(null);

	// Google
	const [googleLoading, setGoogleLoading] = React.useState(false);
	const [googleError, setGoogleError] = React.useState<Error | null>(null);

	// Signout
	const [signoutLoading, setSignoutLoading] = React.useState(false);
	const [signoutError, setSignoutError] = React.useState<Error | null>(null);

	// Signup
	const [signupLoading, setSignupLoading] = React.useState(false);
	const [signupError, setSignupError] = React.useState<Error | null>(null);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(
			firebaseAuth,
			(user) => {
				setUser(user);
				setLoading(false);
			},
			(error) => {
				setError(error);
				setLoading(false);
			}
		);

		return () => unsubscribe();
	}, []);

	const signinWithEmail = async (email: string, password: string) => {
		setEmailLoading(true);
		setEmailError(null);
		try {
			await signInWithEmailAndPassword(firebaseAuth, email, password);
		} catch (error) {
			setEmailError(error as Error);
		} finally {
			setEmailLoading(false);
		}
	};

	const signinWithGoogle = async () => {
		setGoogleLoading(true);
		setGoogleError(null);
		try {
			const provider = new GoogleAuthProvider();
			await signInWithPopup(firebaseAuth, provider);
		} catch (error) {
			setGoogleError(error as Error);
		} finally {
			setGoogleLoading(false);
		}
	};

	const signout = async () => {
		setSignoutLoading(true);
		setSignoutError(null);
		try {
			await firebaseAuth.signOut();
			// await client.clearStore();
		} catch (error) {
			setSignoutError(error as Error);
		} finally {
			setSignoutLoading(false);
			nav('/');
		}
	};

	const signup = async (email: string, password: string) => {
		setSignupLoading(true);
		setSignupError(null);
		try {
			await createUserWithEmailAndPassword(firebaseAuth, email, password);
		} catch (error) {
			setSignupError(error as Error);
		} finally {
			setSignupLoading(false);
		}
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				loading,
				error,
				signout: {
					handler: signout,
					loading: signoutLoading,
					error: signoutError,
				},
				signin: {
					email: {
						handler: signinWithEmail,
						loading: emailLoading,
						error: emailError,
					},
					google: {
						handler: signinWithGoogle,
						loading: googleLoading,
						error: googleError,
					},
				},
				signup: {
					email: {
						handler: signup,
						loading: signupLoading,
						error: signupError,
					},
				},
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthWrapper;

export const useAuthContext = () => React.useContext(AuthContext);
