import React, { PropsWithChildren } from 'react';
import {
	ApolloProvider,
	ApolloClient,
	HttpLink,
	InMemoryCache,
	concat,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuthContext } from '../AuthContext';

const ApolloWrapper: React.FC<PropsWithChildren> = ({ children }) => {
	const { user } = useAuthContext();
	const [error, setError] = React.useState<Error | null>(null);

	const httpLink = new HttpLink({
		uri: process.env.REACT_APP_API_URL,
	});

	httpLink.setOnError((e) => {
		setError(new Error('Apollo Client Error'));
	});

	const asyncAuthLink = setContext(
		(request) =>
			new Promise((success, fail) => {
				if (!user) return success({});
				else {
					user
						.getIdToken()
						.then((authorization) => {
							success({
								headers: { authorization },
							});
						})
						.catch((e) => {
							setError(new Error('Failed to get token'));
							fail(e);
						});
				}
			})
	);

	const client = new ApolloClient({
		link: concat(asyncAuthLink, httpLink),
		cache: new InMemoryCache(),
	});

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
