import { Box, Typography } from '@mui/joy';
import React, { FC, ReactNode } from 'react';
import { JSONTree } from 'react-json-tree';
import { schema } from '../../../../../../../../schema';
import { ChatRole } from '../../../../../../../../schema/Chat/ChatRole';

export interface CollageLayerProps {
	x: number;
	y: number;
	width: number;
	height: number;
	index: number;
	children: mapboxgl.MapboxGeoJSONFeature;
	color: string;
}

const CollageLayer: FC<CollageLayerProps> = ({
	x,
	y,
	width,
	height,
	index,
	children: feature,
	color,
}) => {
	const isEven = index % 2 === 0;

	const [url, setUrl] = React.useState<string | null>(null);

	const [text, setText] = React.useState('');

	schema.chat.get({
		variables: {
			messages: [
				{
					role: ChatRole.user,
					content: `Imagine and describe a ${feature?.properties?.PrimSource} power
					plant in ${feature?.properties?.City} ${feature?.properties?.County} county. 
					Be critical of human's tendancies to extract resources to depletion.

					Max 50 words.
					`,
				},
			],
		},
		onCompleted: (data) => {
			setText(data.Chat.messages[1].content);
		},
		skip: isEven,
	});

	const { loading: imageLoading } = schema.image.get({
		variables: {
			prompt: `A ${feature?.properties?.PrimSource} power plant in ${feature?.properties?.City} ${feature?.properties?.County} county. Mechanically maximal and dramatic.
			`,
			// prompt: `Aerial view of a ${feature?.properties?.PrimSource} power plant in ${feature?.properties?.City} ${feature?.properties?.County} county.
			// The background is an abtracted white landscape, with fractal geometry as though you are looking down at a diagram.
			// The only colors in the photo are white and fuschia.
			// `,
			// prompt: `A poetic & dramtic image of a ${feature?.properties?.PrimSource} power plant in ${feature?.properties?.City} ${feature?.properties?.County} county. Rendered in muted, monochromatic tones of fuschia.`,
			// prompt: `A breathtaking Rembrandt portrait of the operator of a ${feature?.properties?.PrimSource} power plant in ${feature?.properties?.City} ${feature?.properties?.County} county.`,
			count: 1,
		},
		skip: !isEven,
		onCompleted: (data) => {
			setUrl(data.Images[0].url);
		},
	});

	const zIndex = (index + 1) * 10 + 100;

	console.log('feature', feature);

	const tag = 24;

	return (
		<React.Fragment>
			{isEven && (
				<Box
					sx={{
						position: 'absolute',
						top: '72px',
						left: '72px',
						opacity: 0.1,
						xIndex: zIndex + 20,
					}}
				>
					<Typography level="h1" sx={{ fontSize: '20rem' }}></Typography>
				</Box>
			)}
			{isEven && (
				<React.Fragment>
					{/* \top left */}
					<Box
						sx={{
							position: 'absolute',
							left: `calc(${x * 100}vw - 12px)`,
							top: `calc(${y * 100}vh - 12px)`,
							width: tag + 'px',
							height: tag + 'px',
							zIndex,
							borderLeft: '4px solid white',
							borderTop: '4px solid white',
						}}
					/>
					{/* top right */}
					<Box
						sx={{
							position: 'absolute',
							left: `calc(${x * 100}vw + ${width * 100}vw - 12px)`,
							top: `calc(${y * 100}vh - 12px)`,
							width: tag + 'px',
							height: tag + 'px',
							zIndex,
							borderRight: '4px solid white',
							borderTop: '4px solid white',
						}}
					/>
					{/* bottom right */}
					<Box
						sx={{
							position: 'absolute',
							left: `calc(${x * 100}vw + ${width * 100}vw - 12px)`,
							top: `calc(${y * 100}vh + ${height * 100}vh - 12px)`,
							width: tag + 'px',
							height: tag + 'px',
							zIndex,
							borderRight: '4px solid white',
							borderBottom: '4px solid white',
						}}
					/>
					{/* bottom left */}
					<Box
						sx={{
							position: 'absolute',
							left: `calc(${x * 100}vw - 12px)`,
							top: `calc(${y * 100}vh + ${height * 100}vh - 12px)`,
							width: tag + 'px',
							height: tag + 'px',
							zIndex,
							borderLeft: '4px solid white',
							borderBottom: '4px solid white',
						}}
					/>
				</React.Fragment>
			)}
			<Box
				sx={{
					position: 'absolute',
					left: `${x * 100}vw`,
					top: `${y * 100}vh`,
					width: `${width * 100}vw`,
					height: `${height * 100}vh`,
					zIndex: zIndex + 2,
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Box p={2}>
					<Typography
						sx={{
							color: 'white',
						}}
						level="h4"
					>
						{text}
					</Typography>
				</Box>
			</Box>
			<Box
				onClick={(e) => {
					e.stopPropagation();
				}}
				sx={{
					position: 'absolute',
					left: `${x * 100}vw`,
					top: `${y * 100}vh`,
					width: `${width * 100}vw`,
					height: `${height * 100}vh`,
					zIndex,
				}}
			>
				<Box
					sx={{
						width: '100%',
						height: '100%',
						position: 'relative',
					}}
				/>
				{!isEven && (
					<Box
						sx={{
							zIndex: zIndex + 1,
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							background: 'rgba(0,0,0,0.6)',
							backdropFilter: 'blur(4px)',
						}}
					/>
				)}

				{imageLoading || !url ? (
					<Box></Box>
				) : (
					<img
						src={url || ''}
						alt={'AI Generated'}
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							height: '100%',
							width: '100%',
							objectFit: 'cover',
							zIndex: zIndex + 10,
						}}
					/>
				)}
			</Box>
		</React.Fragment>
	);
};

export default CollageLayer;
