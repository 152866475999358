import React, { PropsWithChildren } from 'react';
import Page from '../../components/layout/Page';
import CenterBox from '../../components/layout/CenterBox';
import { Box, Typography } from '@mui/joy';

export interface BlockerProps {
	error?: Error;
}

const Blocker: React.FC<PropsWithChildren<BlockerProps>> = ({
	error,
	children,
}) => {
	return (
		<Page>
			<CenterBox>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
						gap: 2,
					}}
				>
					<Typography level="h2" color="danger">
						{error?.message ?? 'Something went wrong'}
					</Typography>
					{children}
				</Box>
			</CenterBox>
		</Page>
	);
};

export default Blocker;
