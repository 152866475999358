import {
	DocumentNode,
	OperationVariables,
	MutationHookOptions,
	useMutation,
} from "@apollo/client";

export const getMutationHook =
	<TData, TVariables extends OperationVariables>(Mutation: DocumentNode) =>
	(options?: MutationHookOptions<TData, TVariables>) =>
		useMutation<TData, TVariables>(Mutation, options);
