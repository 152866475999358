import React, { useState, useEffect } from 'react';

export type SizeKey = 'phone' | 'tablet' | 'desktop' | 'wide';

export interface ScreenSize extends Record<SizeKey, boolean> {
	width: number;
	height: number;
	size: SizeKey;
	up: (size: SizeKey) => boolean;
	down: (size: SizeKey) => boolean;
}

const getInitialSize = () => {
	const { innerWidth } = window;
	if (innerWidth < 600) {
		return 'phone';
	} else if (innerWidth < 960) {
		return 'tablet';
	} else if (innerWidth < 1280) {
		return 'desktop';
	} else {
		return 'wide';
	}
};

export const useScreenSize = (
	onResize?: (size: SizeKey) => void
): ScreenSize => {
	const [size, setSize] = useState<SizeKey>(getInitialSize());

	useEffect(() => {
		const handleResize = () => {
			const { innerWidth } = window;
			if (innerWidth < 600) {
				setSize('phone');
				if (onResize) onResize('phone');
			} else if (innerWidth < 960) {
				setSize('tablet');
				if (onResize) onResize('tablet');
			} else if (innerWidth < 1280) {
				setSize('desktop');
				if (onResize) onResize('desktop');
			} else {
				setSize('wide');
				if (onResize) onResize('wide');
			}
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return {
		width: window.innerWidth,
		height: window.innerHeight,
		size,
		up: (size: SizeKey) => {
			const sizes = ['phone', 'tablet', 'desktop', 'wide'];
			return sizes.indexOf(size) <= sizes.indexOf(size);
		},
		down: (size: SizeKey) => {
			const sizes = ['phone', 'tablet', 'desktop', 'wide'];
			return sizes.indexOf(size) >= sizes.indexOf(size);
		},
		phone: size === 'phone',
		tablet: size === 'tablet',
		desktop: size === 'desktop',
		wide: size === 'wide',
	};
};
