import React, { useEffect } from 'react';
import Page from '../../components/layout/Page';
import { useAuthContext } from '../../auth/AuthContext';

const Logout: React.FC = () => {
	const { signout } = useAuthContext();

	useEffect(() => {
		signout.handler();
	}, []);

	return <Page></Page>;
};

export default Logout;
