import { Box, Card } from '@mui/joy';
import React, { FC, PropsWithChildren } from 'react';
import { useScreenSize } from '../useScreenSize/useScreenSize';

export interface CenterBoxProps {}

const CenterBox: FC<PropsWithChildren<CenterBoxProps>> = ({ children }) => {
	const { phone, tablet, desktop, wide } = useScreenSize();
	return (
		<Box
			sx={{
				display: 'flex',
				flexFlow: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100%',
			}}
		>
			<Box
				sx={{
					flex: phone ? 1 : undefined,
					width: phone ? '100%' : undefined,
					display: 'flex',
					flexFlow: 'column',
				}}
			>
				{children}
			</Box>
		</Box>
	);
};

export default CenterBox;
