import React, { FC, ReactElement } from 'react';
import { useAuthContext } from '../AuthContext';
import Loading from '../../scenes/Loading';
import Blocker from '../../scenes/Blocker';
import { Navigate } from 'react-router-dom';
import { schema } from '../../schema';
import ProfileSetup from '../../scenes/ProfileSetup';

export type RouteType = 'admin' | 'private' | 'public' | 'anonymous';

export interface RouteElementProps {
	type: RouteType;
	children: ReactElement;
}

const RouteElement: FC<RouteElementProps> = ({ type, children }) => {
	const { user, loading, error } = useAuthContext();
	const {
		data: clientData,
		error: clientError,
		loading: clientLoading,
	} = schema.clientContext.get();

	const detail = clientData?.ClientContext.profileDetail ?? null;

	const componentMap: Record<RouteType, () => ReactElement> = {
		admin: () => {
			if (loading || clientLoading) return <Loading />;
			else if (error || clientError) {
				const e = error ?? clientError;
				if (e?.message.toLowerCase().includes('logged in')) {
					return (
						<Navigate
							to="/login"
							state={{
								from: window.location.pathname,
							}}
						/>
					);
				} else {
					return <Blocker error={error || clientError} />;
				}
			} else if (!detail) return <ProfileSetup />;
			else if (!user) return <Navigate to="/login" />;
			else return children;
		},
		private: () => {
			if (loading || clientLoading) return <Loading />;
			else if (error || clientError) {
				const e = error ?? clientError;
				if (e?.message.toLowerCase().includes('logged in')) {
					return (
						<Navigate
							to="/login"
							state={{
								from: window.location.pathname,
							}}
						/>
					);
				} else {
					return <Blocker error={error || clientError} />;
				}
			} else if (!detail) return <ProfileSetup />;
			else if (!user)
				return (
					<Navigate
						to="/login"
						state={{
							from: window.location.pathname,
						}}
					/>
				);
			else return children;
		},
		public: () => {
			return children;
		},
		anonymous: () => {
			if (error) return <Blocker />;
			else if (user) return <Navigate to="/app" />;
			else return children;
		},
	};

	return componentMap[type]();
};

export default RouteElement;
