import { Box, useTheme } from '@mui/joy';
import React, { PropsWithChildren } from 'react';

const Page: React.FC<PropsWithChildren> = ({ children }) => {
	const { palette, typography } = useTheme();

	return (
		<Box
			sx={{
				background: palette.background.body,
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				...typography['body-md'],
			}}
		>
			{children}
		</Box>
	);
};

export default Page;
