import React, {
	PropsWithChildren,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Box, Alert, useTheme } from '@mui/joy';
import { motion, AnimatePresence } from 'framer-motion';
import { MdCheckCircle, MdError, MdInfo, MdWarning } from 'react-icons/md';

interface Snackbar {
	message: string;
	variant: 'success' | 'error' | 'warning' | 'info';
	key: number;
}

interface SnackbarContext {
	queue: Snackbar[];
	enqueue: (value: Omit<Snackbar, 'key'>) => void;
}

const Context = createContext<SnackbarContext>({
	queue: [],
	enqueue: () => {},
});

const SnackbarProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [queue, setQueue] = useState<Snackbar[]>([]);
	const { palette } = useTheme();

	const drop = (key: number) => {
		setQueue((prev) => prev.filter((item) => item.key !== key));
	};

	// drop periodically
	useEffect(() => {
		const interval = setInterval(() => {
			if (queue.length > 0) {
				drop(queue[0].key);
			}
		}, 2500);

		return () => {
			clearInterval(interval);
		};
	}, [queue]);

	const badge: Record<Snackbar['variant'], ReactNode> = {
		error: (
			<MdError
				style={{
					color: palette.danger[500],
					fontSize: '1.5rem',
				}}
			/>
		),
		info: (
			<MdInfo
				style={{
					color: palette.primary[500],
					fontSize: '1.5rem',
				}}
			/>
		),
		success: (
			<MdCheckCircle
				style={{
					color: palette.success[500],
					fontSize: '1.5rem',
				}}
			/>
		),
		warning: (
			<MdWarning
				style={{
					color: palette.warning[500],
					fontSize: '1.5rem',
				}}
			/>
		),
	};

	return (
		<Context.Provider
			value={{
				queue,
				enqueue: (value: Omit<Snackbar, 'key'>) => {
					setQueue((prev) =>
						[
							...prev,
							{
								...value,
								key: new Date().getTime(),
							},
						].slice(-5)
					);
				},
			}}
		>
			{children}

			<Box
				sx={{
					position: 'fixed',
					bottom: 16,
					right: 16,
					zIndex: 99999,
					display: 'flex',
					flexFlow: 'column',
					gap: 1,
				}}
			>
				<AnimatePresence>
					{queue.map((snackbar) => (
						<motion.div
							key={snackbar.key}
							initial={{ opacity: 0, y: 50 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 50 }}
							transition={{ duration: 0.3 }}
							onClick={() => drop(snackbar.key)}
						>
							<Alert size="lg" endDecorator={badge[snackbar.variant]}>
								{snackbar.message}
							</Alert>
						</motion.div>
					))}
				</AnimatePresence>
			</Box>
		</Context.Provider>
	);
};

export default SnackbarProvider;

export const useSnackbar = () => useContext(Context);
