import { getLazyQueryHook } from '../getLazyQueryHook';
import { getQueryHook } from '../getQueryHook';
import { Chat } from './Chat';
import { ChatMessageInput } from './ChatMessageInput';
import { ChatQuery } from './operations';

export const chat = {
	get: getQueryHook<{ Chat: Chat }, { messages: ChatMessageInput[] }>(
		ChatQuery
	),
	lazy: getLazyQueryHook<{ Chat: Chat }, { messages: ChatMessageInput[] }>(
		ChatQuery
	),
};
