import { gql } from '@apollo/client';
import { getQueryHook } from '../getQueryHook';

export class Image {
	url!: string;
}

export const ImageFragment = gql`
	fragment ImageFragment on Image {
		url
	}
`;

export const ImagesQuery = gql`
	${ImageFragment}
	query Images($prompt: String!, $count: Int) {
		Images(prompt: $prompt, count: $count) {
			...ImageFragment
		}
	}
`;

export const image = {
	get: getQueryHook<{ Images: Image[] }, { prompt: string; count?: number }>(
		ImagesQuery
	),
};
