import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Typography } from '@mui/joy';
import CopyButton from '../../../../components/input/CopyButton';
import { useAuthContext } from '../../../../auth/AuthContext';
import Dashboard from '../../../../components/layout/Dashboard';
import { useNavigate } from 'react-router-dom';

const Account: React.FC = () => {
	const { user } = useAuthContext();

	const nav = useNavigate();

	const [token, setToken] = React.useState('');

	useEffect(() => {
		if (user) {
			user.getIdToken().then(setToken);
		}
	}, [user]);

	return (
		<Dashboard>
			<Box p={4}>
				<Typography
					startDecorator={
						<Avatar src={user?.photoURL || ''} alt={user?.displayName || ''} />
					}
					level="h2"
				>
					My Account
				</Typography>
				<Box p={2} />
				<CopyButton
					size="lg"
					loading={!user || !token}
					color="neutral"
					variant="soft"
					value={token}
				>
					Copy Token
				</CopyButton>
				<Box p={1} />
				<Button
					onClick={() => {
						nav('/logout');
					}}
					size="lg"
					color="neutral"
					variant="soft"
				>
					Logout
				</Button>
			</Box>
		</Dashboard>
	);
};

export default Account;
