import React, {
	FC,
	PropsWithChildren,
	createContext,
	useContext,
	useState,
} from 'react';

export interface SearchContextProps {
	search: string;
	setSearch: (search: string) => void;
}

const Context = createContext<SearchContextProps>({
	search: '',
	setSearch: () => {},
});

const SearchProvider: FC<PropsWithChildren> = ({ children }) => {
	const [search, setSearch] = useState('');
	return (
		<Context.Provider
			value={{
				search,
				setSearch,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default SearchProvider;

export const useSearch = () => useContext(Context);
